@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    min-height: 100%;
}

@layer base {
    body {
        @apply bg-slate-100;
    }

    a {
        @apply text-blue-900;
        @apply underline;
    }
}

@media only screen and (max-width: 576px) {
    body {
        font-size: 14px;
    }
}


.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__header select{
    font-size: 13px;
    line-height: 1.3;
    margin-top: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select {
    margin: 0 10px !important;
}

.react-datepicker-popper {
    z-index: 999 !important;
}
